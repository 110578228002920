// @flow strict
import React from 'react';
import Author from './Author';
import Menu from './Menu';
import styles from './Navbar.module.scss';
import { useSiteMetadata } from '../../hooks';

type Props = {
  isIndex?: boolean,
};

const Navbar = ({ isIndex }: Props) => {
  const { author, menu } = useSiteMetadata();

  return (
    <div className={styles['navbar']}>
      <div className={styles['navbar__inner']}>
        <Author author={author} isIndex={isIndex} />
        <Menu menu={menu} />
      </div>
    </div>
  );
};

export default Navbar;
