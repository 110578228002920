// @flow strict
import React from 'react';
import { withPrefix, Link } from 'gatsby';
import styles from './Author.module.scss';

type Props = {
  author: {
    name: string,
    bio: string,
    logo: string
  },
  isIndex: ?boolean
};

const Author = ({ author }: Props) => (
  <div className={styles['author']}>
    <Link to="/">
      <img
        src={withPrefix(author.logo)}
        className={styles['author__logo']}
        width="auto"
        height="auto"
        alt={author.name}
      />
    </Link>
  </div>
);

export default Author;
